.root {
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  color: #777;
  font-size: 0.8em;
}

.root a {
  color: #777;
}
