.button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 3px;
  padding: 5px;
  user-select: none;
  color: black;
  font-weight: bold;
  background-color: white;
}

.button.large {
  font-size: 64px;
}

.button:active {
  background-color: #ccc;
}
