.root {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background: repeating-linear-gradient(
    135deg,
    #020,
    #020 1px,
    #131 1.5px,
    #131 2.5px,
    #020 3px
  );
  color: white;
}

.mainContent {
  margin: auto 0;
  flex: 0;
}

.footerContent {
  flex: 0;
}
